import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Tabs} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useLocationApi} from "../../api";
import {DisabledChip} from "../../controls/DisabledChip";
import {useLocalization} from "../../i18n";
import {useLocationPermission} from "../../tenant";
import {useSearchParam} from "../../utils/search";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {LocationAuditTimeline} from "./LocationAuditTimeline";
import {LocationLinkChip} from "./LocationChip";
import {LocationCompartmentList} from "./LocationCompartmentList";
import {LocationSettings} from "./LocationSettings";

export function LocationView() {
	const {t} = useLocalization();
	const locationId = useNumericParam("locationId");
	const [tab, setTab] = useSearchParam("tab", "compartments");

	const {getLocation} = useLocationApi();

	const {loading, error, result: location} = useAsync(getLocation, [locationId]);

	const canEditSettings = useLocationPermission("SETTINGS_EDIT", locationId);

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>{t("locations.title")}</BreadcrumbLink>
				<BreadcrumbItem>{location?.name}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={location?.name || ""}
				subTitle={location?.parent && <LocationLinkChip location={location.parent} />}
				titleAdornment={location && !location.enabled && <DisabledChip />}
			/>
			<Tabs
				value={tab}
				onChange={(_, tab) => setTab(tab)}
				items={[
					{label: t("compartments.title"), value: "compartments"},
					{label: t("audit.title"), value: "audit"},
					...(canEditSettings
						? [{label: t("settings.title"), value: "settings"}]
						: []),
				]}
			/>
			{tab == "compartments" && <LocationCompartmentList />}
			{tab == "audit" && <LocationAuditTimeline />}
			{tab == "settings" && canEditSettings && <LocationSettings />}
		</View>
	);
}
