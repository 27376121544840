import {Button, Chip} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Tabs} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useGroupApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantPermission} from "../../tenant";
import {useSearchParam} from "../../utils/search";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {GroupAuditTimeline} from "./GroupAuditTimeline";
import {GroupRoleList} from "./GroupRoleList";
import {GroupUserList} from "./GroupUserList";

export function GroupView() {
	const {t} = useLocalization();
	const groupId = useNumericParam("groupId");
	const {getGroup} = useGroupApi();

	const {loading, error, result} = useAsync(getGroup, [groupId]);

	const canEdit = useTenantPermission("GROUP_EDIT");

	const [tab, setTab] = useSearchParam("tab", "users");

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to="..">{t("groups.title")}</BreadcrumbLink>
				<BreadcrumbItem>{result?.name}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={result?.name ?? ""}
				titleAdornment={result?.defaultGroup && <Chip label={t("groups.defaultGroup.title")} />}
				actions={canEdit && (
					<Button
						color="primary"
						variant="outlined"
						component={Link}
						to={`edit`}
					>
						{t("edit")}
					</Button>
				)}
			/>
			<Tabs
				value={tab}
				onChange={(_, tab) => setTab(tab)}
				items={[
					{label: t("groups.users.title"), value: "users"},
					{label: t("groups.roles.title"), value: "roles"},
					{label: t("audit.title"), value: "audit"},
				]}
			/>
			{tab == "users" && <GroupUserList />}
			{tab == "roles" && <GroupRoleList />}
			{tab == "audit" && <GroupAuditTimeline />}
		</View>
	);
}
