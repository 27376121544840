import {CardContent, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {Filter, RadioGroup, SpringPageable} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {CompartmentQuery, CompartmentStates, useCompartmentApi, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {LocationChip} from "../location/LocationChip";
import {LocationSelect} from "../location/LocationSelect";

export type CompartmentFilter = Omit<CompartmentQuery, keyof SpringPageable>;

interface CompartmentFilterProps {
	value: CompartmentFilter;
	onChange: (value: CompartmentFilter) => any;
}

export function CompartmentFilter({value, onChange}: CompartmentFilterProps) {
	const {t, s} = useLocalization();
	const tenantId = useTenantId();

	const {getLocation} = useLocationApi();
	const {getCompartmentSizes} = useCompartmentApi();

	const location = useAsync(async (locationId?: number) => {
		if (locationId) {
			return await getLocation(locationId);
		}
	}, [value.location]);

	const sizes = useAsync(getCompartmentSizes, [tenantId]);

	return (
		<Filter
			label={t("filter.title")}
			active={[
				value.status && (
					<Chip
						label={`${t("compartments.status.title")}: ${t(`compartments.status.${value.status}`)}`}
						onDelete={() => onChange({...value, status: undefined})}
					/>
				),
				location.result && (
					<LocationChip
						location={location.result}
						onDelete={() => onChange({...value, location: undefined})}
					/>
				),
				value.compartmentSize && (
					<Chip
						label={`${t("compartments.size")}: ${value.compartmentSize}`}
						onDelete={() => onChange({...value, compartmentSize: undefined})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<RadioGroup
							label={t("compartments.size")}
							value={value.compartmentSize || "__all"}
							onChange={compartmentSize =>
								onChange({
									...value,
									compartmentSize: compartmentSize == "__all" ? undefined : compartmentSize,
								})}
							options={["__all", ...(sizes.result ?? [])]}
							renderLabel={value => (value == "__all" ? t("compartments.sizes.all") : value)}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<RadioGroup
							label={t("compartments.status.title")}
							value={value.status || "all"}
							onChange={newStatus =>
								onChange({...value, status: newStatus == "all" ? undefined : newStatus})}
							options={["all", ...CompartmentStates]}
							renderLabel={s("compartments.status")}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<LocationSelect
							value={location.result ?? null}
							onChange={location => onChange({...value, location: location?.id})}
							fullWidth
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}
