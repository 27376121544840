import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {BookingSettings, CompartmentSettings, createApiClient} from "./client";
import {get_QueryLocations, LocationCreation, LocationMutation} from "./client.gen";

export function useLocationApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createLocationApi(tenantId, accessToken), [tenantId, accessToken]);
}

export type LocationQuery = TypeOf<get_QueryLocations["parameters"]>["query"];

export const LocationTypes = [
	"COUNTRY",
	"CITY",
	"AREA",
	"ESTATE",
	"BUILDING",
	"WING",
	"FLOOR",
	"ROOM",
	"LOCKER_BANK",
] as const;

function createLocationApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getLocation: (locationId: number) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}", {
				path: {tenantId, locationId},
			}),
		updateLocation: (locationId: number, mutation: LocationMutation) =>
			api.put("/api/tenants/{tenantId}/locations/{locationId}", {
				path: {tenantId, locationId},
				body: mutation,
			}),
		deleteLocation: (locationId: number) =>
			api.delete("/api/tenants/{tenantId}/locations/{locationId}", {
				path: {tenantId, locationId},
			}),
		createLocation: (locationCreation: LocationCreation) =>
			api.post("/api/tenants/{tenantId}/locations", {
				path: {tenantId},
				body: locationCreation,
			}),
		queryLocations: (query: LocationQuery) =>
			api.get("/api/tenants/{tenantId}/locations", {
				path: {tenantId},
				query,
			}),
		getLocationTrees: () =>
			api.get("/api/tenants/{tenantId}/location-trees", {
				path: {tenantId},
			}),
		getLocationCompartments: (locationId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}/compartments", {
				path: {tenantId, locationId},
				query: pageable,
			}),
		getLocationAuditLog: (locationId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}/audit", {
				path: {tenantId, locationId},
				query: pageable,
			}),
		getLocationCompartmentTemplate: (locationId: number) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}/compartments/template", {
				path: {tenantId, locationId},
			}),
		getLocationSettings: (locationId: number) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}/settings", {
				path: {tenantId, locationId},
			}),
		getLocationBookingSettings: (locationId: number) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}/settings/booking", {
				path: {tenantId, locationId},
			}),
		updateLocationBookingSettings: (locationId: number, bookingSettings: BookingSettings) =>
			api.put("/api/tenants/{tenantId}/locations/{locationId}/settings/booking", {
				path: {tenantId, locationId},
				body: bookingSettings,
			}),
		getLocationCompartmentSettings: (locationId: number) =>
			api.get("/api/tenants/{tenantId}/locations/{locationId}/settings/compartment", {
				path: {tenantId, locationId},
			}),
		updateLocationCompartmentSettings: (locationId: number, compartmentSettings: CompartmentSettings) =>
			api.put("/api/tenants/{tenantId}/locations/{locationId}/settings/compartment", {
				path: {tenantId, locationId},
				body: compartmentSettings,
			}),
	};
}
