import {Button, Dialog, DialogContent, DialogTitle, Grid, Typography} from "@mui/material";
import {Checkbox, useFlag} from "@variocube/app-ui";
import React, {Fragment} from "react";
import {Link as RouterLink} from "react-router-dom";
import {PERMISSIONS} from "../api";
import {useLocalization} from "../i18n";
import {useEmulatePermissions} from "../tenant";
import {useUser} from "../user";

export function FooterBar() {
	const {t} = useLocalization();
	const {superUser} = useUser();

	return (
		<Grid container spacing={2} sx={{px: 1, py: 0.5}} alignItems="center" displayPrint="none">
			<Grid item style={{flexGrow: 1}}>
				<Typography variant="overline" color="textSecondary">© SMALOX</Typography>
			</Grid>
			{location.hostname == "localhost" && (
				<Grid item>
					<EmulatePermissionsButton />
				</Grid>
			)}
			{superUser && (
				<Grid item>
					<Button component={RouterLink} to="/tenants" color="inherit" size="small">
						{t("tenants.switch")}
					</Button>
				</Grid>
			)}
			<Grid item>
				<Typography variant="overline">
					<Button component="a" href="/docs/api" target="_blank" color="inherit" size="small">
						{t("api-docs")}
					</Button>
				</Typography>
			</Grid>
			<Grid item>
				<Button
					component="a"
					href="https://www.stiwa.com/impressum"
					target="_blank"
					size="small"
					color="inherit"
				>
					{t("imprint")}
				</Button>
			</Grid>
		</Grid>
	);
}

function EmulatePermissionsButton() {
	const [open, setOpen, clearOpen] = useFlag(false);

	const [emulatedPermissions, setEmulatedPermissions] = useEmulatePermissions();

	return (
		<Fragment>
			<Button onClick={setOpen} size="small" color="inherit">
				Emulate Permissions
			</Button>
			<Dialog open={open} onClose={clearOpen}>
				<DialogTitle>Emulate Permissions</DialogTitle>
				<DialogContent>
					{PERMISSIONS.map(permission => (
						<Checkbox
							key={permission}
							label={permission}
							checked={emulatedPermissions.includes(permission)}
							onChange={checked => {
								if (checked) {
									setEmulatedPermissions([...emulatedPermissions, permission]);
								}
								else {
									setEmulatedPermissions(emulatedPermissions.filter(p => p != permission));
								}
							}}
						/>
					))}
				</DialogContent>
			</Dialog>
		</Fragment>
	);
}
