import React from "react";
import {useGroupApi} from "../../api";
import {useNumericParam} from "../../utils/useParam";
import {AuditTimeline} from "../audit/AuditTimeline";

export function GroupAuditTimeline() {
	const groupId = useNumericParam("groupId");
	const {getGroupAuditLog} = useGroupApi();
	return <AuditTimeline load={getGroupAuditLog} param={groupId} />;
}
