import {Box} from "@mui/material";
import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {CompartmentSettings, useTenantApi} from "../../api";
import {useReloadTenant, useTenantId} from "../../tenant";
import {CompartmentSettingsEditForm} from "./CompartmentSettingsEditForm";

export function TenantCompartmentSettings() {
	const tenantId = useTenantId();
	const {getTenantCompartmentSettings, updateTenantCompartmentSettings} = useTenantApi();
	const reloadTenant = useReloadTenant();
	const {loading, error, result} = useAsync(getTenantCompartmentSettings, [tenantId]);

	async function handleSave(compartmentSettings: CompartmentSettings) {
		await updateTenantCompartmentSettings(tenantId, compartmentSettings);
		await reloadTenant();
	}

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return (
		<Box maxWidth="sm">
			<CompartmentSettingsEditForm loading={loading} onSave={handleSave} compartmentSettings={result} />
		</Box>
	);
}
