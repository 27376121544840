import {Typography} from "@mui/material";
import {
	DataTable,
	DataTableColumn,
	SwitchableTemporalFormat,
	tryParseInstant,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {AuditLogEntry, useTenantApi} from "../../api";
import {TFunc, useLocalization} from "../../i18n";
import {useTenantId} from "../../tenant";
import {View, ViewHeader} from "../View";
import {AuditLogEntryDetailsButton} from "./AuditLogEntryDetailsButton";
import {AuditLogFilter} from "./AuditLogFilter";

export function AuditLog() {
	const {t} = useLocalization();
	const {queryTenantAuditLog} = useTenantApi();
	const tenantId = useTenantId();

	const columns = useMemo(() => createColumns(t), [t]);

	const {onPageChange, onSort, ...storage} = useDataTableStorage("AuditLogList.paging");
	const pageable = useSpringPageable(storage);

	const [filter, setFilter] = useStorage<AuditLogFilter>("AuditLogList.filter", {});

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryTenantAuditLog, [tenantId, query]);
	const {rows, page} = useSpringPage(result);

	return (
		<View>
			<ViewHeader
				title={t("audit.title")}
			/>
			<AuditLogFilter value={filter} onChange={setFilter} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				{...storage}
			/>
		</View>
	);
}

function createColumns(t: TFunc): DataTableColumn<AuditLogEntry>[] {
	return [
		{
			label: t("audit.id"),
			field: "id",
			sortable: true,
			default: true,
			component: ({row}) => (
				<Typography fontFamily="JetBrains Mono" fontSize="large">
					{row.id}
				</Typography>
			),
		},
		{
			label: t("audit.created"),
			field: "created",
			sortable: true,
			default: true,
			component: ({row}) => <SwitchableTemporalFormat value={tryParseInstant(row.created)} />,
		},
		{
			label: t("audit.actor"),
			field: "actor",
			sortable: true,
			default: true,
		},
		{
			label: t("audit.action"),
			field: "action",
			sortable: true,
			default: true,
			component: ({row}) => <Typography>{t(`audit.actions.${row.action}`)}</Typography>,
		},
		{
			label: t("audit.showDetails"),
			field: "",
			sortable: false,
			default: true,
			component: ({row}) => <AuditLogEntryDetailsButton entry={row} />,
		},
	];
}
