import {CardContent, CardHeader, FormHelperText, Grid} from "@mui/material";
import {
	BreadcrumbItem,
	BreadcrumbLink,
	Breadcrumbs,
	Checkbox,
	EditForm,
	Now,
	PlainDateTime,
	PlainDateTimePicker,
} from "@variocube/app-ui";
import React, {useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {
	Compartment,
	LocationTree,
	TenantUser,
	useBookingApi,
	useCompartmentApi,
	useLocationApi,
	useTenantUserApi,
} from "../../api";
import {useLocalization} from "../../i18n";
import {useSearchParam} from "../../utils/search";
import {CompartmentSelect} from "../compartment/CompartmentSelect";
import {LocationSelect} from "../location/LocationSelect";
import {UserSelect} from "../user/UserSelect";
import {View, ViewHeader} from "../View";

export function BookingCreate() {
	const {t} = useLocalization();
	const navigate = useNavigate();
	const UserTimeZoneId = Now.zonedDateTimeISO().timeZone;
	const [userParam] = useSearchParam("user", "");
	const [compartmentParam] = useSearchParam("compartment", "");

	const [location, setLocation] = useState<LocationTree | null>(null);
	const [compartment, setCompartment] = useState<Compartment | null>(null);
	const [users, setUsers] = useState<TenantUser[]>([]);
	const [from, setFrom] = useState<PlainDateTime | null>(Now.plainDateTimeISO());
	const [until, setUntil] = useState<PlainDateTime | null>(null);
	const [selfManaged, setSelfManaged] = useState(false);
	const [temporary, setTemporary] = useState(false);

	const {createBooking} = useBookingApi();
	const {getUser} = useTenantUserApi();
	const {getCompartment} = useCompartmentApi();
	const {getLocationSettings} = useLocationApi();

	const loadUser = useAsync(async (username?: string) => {
		if (username) {
			setUsers([await getUser(username)]);
		}
	}, [userParam]);

	const loadCompartment = useAsync(async (compartmentId?: string) => {
		if (compartmentId) {
			setCompartment(await getCompartment(Number(compartmentId)));
		}
	}, [compartmentParam]);

	useAsync(async (locationId?: number) => {
		if (locationId) {
			const locationSettings = await getLocationSettings(locationId);
			setTemporary(locationSettings.booking.temporary ?? false);
		}
	}, [compartment?.location.id]);

	async function handleSave() {
		if (!compartment) {
			throw new Error(t("bookings.create.errorNoCompartment"));
		}

		if (users.length == 0) {
			throw new Error(t("bookings.create.errorNoUsers"));
		}

		if (!from) {
			throw new Error(t("bookings.create.errorNoFrom"));
		}

		const createdBooking = await createBooking({
			compartmentId: compartment.id,
			users: users.map(user => user.user.username),
			from: from.toZonedDateTime(UserTimeZoneId).toInstant().toString(),
			until: until?.toZonedDateTime(UserTimeZoneId).toInstant().toString(),
			temporary,
			selfManaged,
		});
		console.log("Created", createdBooking);
		navigate(`../${createdBooking.id}`);
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."}>{t("bookings.title")}</BreadcrumbLink>
				<BreadcrumbItem>{t("bookings.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("bookings.create.title")} />
			<EditForm
				loading={loadUser.loading || loadCompartment.loading}
				onSave={handleSave}
				labels={t}
			>
				<CardHeader title={t("bookings.compartment")} />
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<LocationSelect
								value={location}
								onChange={setLocation}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<CompartmentSelect
								value={compartment}
								onChange={setCompartment}
								status="AVAILABLE"
								location={location}
								required
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardHeader title={t("bookings.users.title")} />
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} md={8}>
							<UserSelect
								value={users}
								onChange={setUsers}
								required
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardContent>
					<Checkbox label={t("bookings.selfManaged.title")} checked={selfManaged} onChange={setSelfManaged} />
					<FormHelperText>{t("bookings.selfManaged.helperText")}</FormHelperText>
				</CardContent>
				<CardHeader title={t("bookings.timeframe")} />
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<PlainDateTimePicker
								label={t("bookings.from")}
								value={from}
								onChange={setFrom}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<PlainDateTimePicker
								label={t("bookings.until")}
								value={until}
								onChange={setUntil}
								fullWidth
							/>
						</Grid>
					</Grid>
				</CardContent>
				<CardContent>
					<Checkbox label={t("bookings.temporary.title")} checked={temporary} onChange={setTemporary} />
					<FormHelperText>{t("bookings.temporary.helperText")}</FormHelperText>
				</CardContent>
			</EditForm>
		</View>
	);
}
