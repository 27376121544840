import {Button} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Tabs} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {useTenantUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useSearchParam} from "../../utils/search";
import {useParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {UserAuditTimeline} from "./UserAuditTimeline";
import {UserBookingList} from "./UserBookingList";
import {UserGroupList} from "./UserGroupList";

export function UserView() {
	const {t} = useLocalization();
	const username = useParam("username");
	const {getUser} = useTenantUserApi();

	const {loading, error, result} = useAsync(getUser, [username]);

	const [tab, setTab] = useSearchParam("tab", "bookings");

	return (
		<View loading={loading} error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to="..">{t("users.title")}</BreadcrumbLink>
				<BreadcrumbItem>{username}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader
				title={result?.user.displayName ?? ""}
				subTitle={result?.user.email}
				actions={
					<Button
						color="primary"
						variant="contained"
						component={Link}
						to={`../../bookings/new?user=${result?.user.username}`}
					>
						{t("bookings.create.title")}
					</Button>
				}
			/>
			<Tabs
				value={tab}
				onChange={(_, tab) => setTab(tab)}
				items={[
					{label: t("bookings.title"), value: "bookings"},
					{label: t("groups.title"), value: "groups"},
					{label: t("audit.title"), value: "audit"},
				]}
			/>
			{tab == "bookings" && <UserBookingList />}
			{tab == "groups" && <UserGroupList />}
			{tab == "audit" && <UserAuditTimeline />}
		</View>
	);
}
