import React from "react";
import {Route, Routes} from "react-router-dom";
import {UserCreate} from "./UserCreate";
import {UserList} from "./UserList";
import {UserView} from "./UserView";

export function Users() {
	return (
		<Routes>
			<Route path="new" element={<UserCreate />} />
			<Route path=":username" element={<UserView />} />
			<Route index element={<UserList />} />
		</Routes>
	);
}
