import React from "react";
import {Route, Routes} from "react-router-dom";
import {GroupCreate} from "./GroupCreate";
import {GroupEdit} from "./GroupEdit";
import {GroupList} from "./GroupList";
import {GroupView} from "./GroupView";

export function Groups() {
	return (
		<Routes>
			<Route path="new" element={<GroupCreate />} />
			<Route path=":groupId" element={<GroupView />} />
			<Route path=":groupId/edit" element={<GroupEdit />} />
			<Route index element={<GroupList />} />
		</Routes>
	);
}
