import {Box} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {ErrorAlert} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {BookingSettings, CompartmentSettings, useLocationApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {BookingSettingsEditForm} from "../settings/BookingSettingsEditForm";
import {CompartmentSettingsEditForm} from "../settings/CompartmentSettingsEditForm";

export function LocationSettings() {
	const locationId = useNumericParam("locationId");
	const {t} = useLocalization();

	const {
		getLocationBookingSettings,
		updateLocationBookingSettings,
		getLocationCompartmentSettings,
		updateLocationCompartmentSettings,
	} = useLocationApi();

	const {loading, error, result} = useAsync((locationId: number) =>
		Promise.all([
			getLocationBookingSettings(locationId),
			getLocationCompartmentSettings(locationId),
		]), [locationId]);

	async function handleSaveBooking(bookingSettings: BookingSettings) {
		await updateLocationBookingSettings(locationId, bookingSettings);
	}

	async function handleSaveCompartment(compartmentSettings: CompartmentSettings) {
		await updateLocationCompartmentSettings(locationId, compartmentSettings);
	}

	const [bookingSettings, compartmentSettings] = result ?? [];

	if (error) {
		return <ErrorAlert error={error} />;
	}

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<BookingSettingsEditForm
						title={t("bookings.title")}
						loading={loading}
						onSave={handleSaveBooking}
						bookingSettings={bookingSettings}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<CompartmentSettingsEditForm
						title={t("compartments.title")}
						loading={loading}
						onSave={handleSaveCompartment}
						compartmentSettings={compartmentSettings}
					/>
				</Grid>
			</Grid>
		</Box>
	);
}
