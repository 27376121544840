import {Box, Chip, ChipProps, Stack, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {Location, LocationAncestry, LocationType} from "../../api";
import {useTenantId} from "../../tenant";
import {LocationIcon} from "./LocationIcon";
import {formatLocationPath} from "./path";

interface LocationChipProps extends Pick<ChipProps, "color" | "variant" | "onDelete"> {
	location: Location | LocationAncestry;
}

export function LocationChip({location, ...chipProps}: LocationChipProps) {
	return (
		<Chip
			icon={<LocationChipIcon type={location.type} />}
			label={<LocationChipLabel location={location} />}
			{...chipProps}
		/>
	);
}

interface LocationLinkChipProps {
	location: Location | LocationAncestry;
	ancestry?: boolean;
}

export function LocationLinkChip({location, ancestry = true, ...chipProps}: LocationLinkChipProps) {
	const tenantId = useTenantId();
	return (
		<Chip
			icon={<LocationChipIcon type={location.type} />}
			label={<LocationChipLabel location={location} ancestry={ancestry} />}
			component={Link}
			to={`/${tenantId}/locations/${location.id}`}
			variant="outlined"
			color="secondary"
			clickable
			{...chipProps}
		/>
	);
}

function LocationChipIcon({type}: { type: LocationType | undefined }) {
	return (
		<Box px={0.5} display="flex" alignItems="center" justifyContent="center">
			<LocationIcon type={type} />
		</Box>
	);
}

function LocationChipLabel({location, ancestry = true}: LocationLinkChipProps) {
	const path = formatLocationPath(location.parent);
	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<Typography variant="body1">
				{location.name}
			</Typography>
			{ancestry && (
				<Typography variant="body2" color="textSecondary">
					{path}
				</Typography>
			)}
		</Stack>
	);
}
