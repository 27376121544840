import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {createApiClient} from "./client";
import {CompartmentCreation, CompartmentMutation, get_QueryCompartments} from "./client.gen";

export function useCompartmentApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createCompartmentApi(tenantId, accessToken), [tenantId, accessToken]);
}

export type CompartmentQuery = TypeOf<get_QueryCompartments["parameters"]>["query"];

export const CompartmentStates = [
	"AVAILABLE",
	"BOOKED",
] as const;

function createCompartmentApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		createCompartment: (compartmentCreation: CompartmentCreation) =>
			api.post("/api/tenants/{tenantId}/compartments", {
				path: {tenantId},
				body: compartmentCreation,
			}),
		updateCompartment: (compartmentId: number, compartmentMutation: CompartmentMutation) =>
			api.put("/api/tenants/{tenantId}/compartments/{compartmentId}", {
				path: {tenantId, compartmentId},
				body: compartmentMutation,
			}),
		getCompartment: (compartmentId: number) =>
			api.get("/api/tenants/{tenantId}/compartments/{compartmentId}", {
				path: {tenantId, compartmentId},
			}),
		deleteCompartment: (compartmentId: number) =>
			api.delete("/api/tenants/{tenantId}/compartments/{compartmentId}", {
				path: {tenantId, compartmentId},
			}),
		queryCompartments: (query: CompartmentQuery) =>
			api.get("/api/tenants/{tenantId}/compartments", {
				path: {tenantId},
				query,
			}),
		getCompartmentSizes: () =>
			api.get("/api/tenants/{tenantId}/compartments/sizes", {
				path: {tenantId},
			}),
		getCompartmentBookings: (compartmentId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/compartments/{compartmentId}/bookings", {
				path: {tenantId, compartmentId},
				query: pageable,
			}),
		getCompartmentAuditLog: (compartmentId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/compartments/{compartmentId}/audit", {
				path: {tenantId, compartmentId},
				query: pageable,
			}),
		assignCompartmentLock: (compartmentId: number, lockId: string | undefined) =>
			api.put("/api/tenants/{tenantId}/compartments/{compartmentId}/lock", {
				path: {tenantId, compartmentId},
				body: {lockId},
			}),
	};
}
