import {Alert, AlertTitle, Box, Button, Card, List, ListItemButton, ListItemText} from "@mui/material";
import {SearchForm} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {TenantQuery, useTenantApi} from "../api";
import {FullScreenMessage} from "../controls";
import {useLocalization} from "../i18n";
import {LoginState} from "../LoginState";
import {useUser} from "../user";
import {View, ViewHeader} from "./View";

export function TenantSelection() {
	const {t} = useLocalization();
	const {queryTenants} = useTenantApi();
	const navigate = useNavigate();
	const {superUser} = useUser();

	const [query, setQuery] = useState<TenantQuery>({});
	const {loading, error, result} = useAsync(queryTenants, [query]);

	// Automatically navigate to tenant, if there is only one
	useEffect(() => {
		if (result) {
			if (result.content?.length == 1) {
				navigate(`/${result.content[0].id}`);
			}
		}
	}, [result]);

	if (result?.totalElements === 0) {
		return (
			<FullScreenMessage>
				<Alert severity="error">
					<AlertTitle>{t("tenants.selection.noTenants.title")}</AlertTitle>
					{t("tenants.selection.noTenants.description")}
				</Alert>
			</FullScreenMessage>
		);
	}

	return (
		<LoginState loading={loading} error={error}>
			<Box py={4}>
				<View maxWidth="md">
					<ViewHeader
						title={t("tenants.selection.title")}
						actions={superUser && (
							<Button variant="outlined" color="primary" component={Link} to="new">
								{t("tenants.create.title")}
							</Button>
						)}
					/>
					<SearchForm
						label={t("filter.search")}
						fullWidth
						onSearch={search => setQuery({...query, search})}
					/>
					<Card>
						<List>
							{result?.content?.map((tenant) => (
								<ListItemButton key={tenant.id} component={Link} to={`/${tenant.id}`}>
									<ListItemText primary={tenant.name} />
								</ListItemButton>
							))}
						</List>
					</Card>
				</View>
			</Box>
		</LoginState>
	);
}
