import {createApiFetcher, createQueryString} from "@variocube/app-ui";
import {createApiClient as createGeneratedApiClient, EndpointParameters, Method} from "./client.gen";

const baseUrl = location.hostname == "localhost" ? "http://localhost:5000" : "";

export function createApiClient(accessToken?: string) {
	const baseHeaders: HeadersInit = accessToken ? {Authorization: `Bearer ${accessToken}`} : {};
	const {fetch} = createApiFetcher(baseUrl, baseHeaders);

	return createGeneratedApiClient(async (method: Method, url: string, params: EndpointParameters | undefined) => {
		// build query string
		const qs = params?.query ? "?" + createQueryString(params.query) : "";

		// resolve path variables
		const resolvedUrl = url.replaceAll(/{[a-zA-Z]+}/g, (pathVariable) => {
			const pathVariableName = pathVariable.substring(1, pathVariable.length - 1);
			const value = params?.path && params?.path[pathVariableName];
			if (!value) {
				throw new Error(`Missing path variable ${pathVariableName}.`);
			}
			return encodeURIComponent(`${value}`);
		});

		const headers: HeadersInit = params?.body ? {"Content-Type": "application/json"} : {};

		const response = await fetch(resolvedUrl + qs, {
			method,
			body: params?.body ? JSON.stringify(params.body) : undefined,
			headers,
		});

		const contentType = response.headers.get("content-type");

		if (contentType && /application\/.*json/.test(contentType)) {
			return await response.json();
		}
	});
}

export function getBaseUrl() {
	return baseUrl;
}

export * from "./client.gen";
