import {AppBar, Box, Drawer, IconButton, useMediaQuery, useTheme} from "@mui/material";
import {MenuIcon} from "@variocube/app-ui/src/icons";
import {useFlag} from "@variocube/app-ui/src/utils";
import React, {PropsWithChildren, useEffect} from "react";
import {Logo} from "../Logo";
import {FooterBar} from "./FooterBar";
import {SideNav} from "./SideNav";
import {TopNav} from "./TopNav";

export function SmaloxAppShell({children}: PropsWithChildren<{}>) {
	const theme = useTheme();
	const [drawerOpen, setDrawerOpen, clearDrawerOpen, toggleDrawerOpen] = useFlag(false);

	const drawerPermanent = useMediaQuery(theme.breakpoints.up("md"));
	useEffect(() => {
		if (drawerPermanent) {
			setDrawerOpen();
		}
	}, [drawerPermanent]);

	return (
		<Box
			sx={{
				display: "flex",
				flexFlow: "column nowrap",
				"@media screen": {
					height: "100vh",
					overflowY: "scroll",
				},
				"@media print": {
					padding: 0,
					margin: 0,
				},
			}}
		>
			<AppBar
				elevation={0}
				position="static"
				color="inherit"
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					backgroundColor: "paper.elevation1",
					color: "text.primary",
					displayPrint: "none",
				}}
			>
				<Box
					sx={{
						position: "relative",
						display: "flex",
						flexFlow: "row nowrap",
						justifyContent: "space-between",
						alignItems: "center",
						px: 2,
					}}
				>
					{!drawerPermanent && (
						<IconButton
							edge="start"
							sx={{marginRight: 1}}
							color="inherit"
							aria-label="menu"
							onClick={toggleDrawerOpen}
							size="large"
						>
							<MenuIcon />
						</IconButton>
					)}
					<Box
						sx={{
							py: 2,
							...(!drawerPermanent && {
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
							}),
						}}
					>
						<Logo display="block" height={18} />
					</Box>
					{<TopNav />}
				</Box>
			</AppBar>
			<Box
				sx={{
					flex: "1 1 auto",
					display: "flex",
					flexFlow: "row nowrap",
				}}
			>
				<Drawer
					variant={!drawerPermanent ? "temporary" : "permanent"}
					sx={{
						displayPrint: "none",
						[`& .MuiDrawer-paper`]: {
							position: "relative",
							whiteSpace: "nowrap",
							overflowX: "hidden",
							width: 200,
							boxSizing: "border-box",
						},
						["& .MuiPaper-elevation0"]: {
							backgroundColor: "paper.elevation1",
						},
					}}
					open={drawerOpen}
					onClick={!drawerPermanent ? clearDrawerOpen : undefined}
					onClose={clearDrawerOpen}
				>
					{!drawerPermanent && (
						<Box
							sx={{
								px: 2,
								py: 2,
							}}
						>
							<Logo height={18} />
						</Box>
					)}
					<SideNav />
				</Drawer>
				<Box
					sx={{
						py: 4,
						flex: 1,
						maxWidth: "100%",
						"@media print": {padding: 0, margin: 0},
					}}
				>
					{children}
				</Box>
			</Box>

			<Box
				sx={{
					borderTop: 1,
					borderColor: "divider",
					backgroundColor: theme.palette.background.paper,
					color: "text.primary",
					displayPrint: "none",
				}}
			>
				<FooterBar />
			</Box>
		</Box>
	);
}
