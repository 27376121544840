import {CardContent, FormHelperText, Grid} from "@mui/material";
import {Checkbox, EditForm, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {Group, GroupMutation} from "../../api";
import {useLocalization} from "../../i18n";

interface GroupEditFormProps {
	loading: boolean;
	group?: Group;
	onSave: (mutation: GroupMutation) => Promise<any>;
}

export function GroupEditForm(props: GroupEditFormProps) {
	const {loading, group, onSave} = props;
	const {t} = useLocalization();

	const [name, setName] = useState("");
	const [defaultGroup, setDefaultGroup] = useState(false);

	useEffect(() => {
		if (group) {
			setName(group.name);
			setDefaultGroup(group.defaultGroup);
		}
	}, [group]);

	async function handleSave() {
		await onSave({
			name,
			defaultGroup,
		});
	}

	return (
		<EditForm loading={loading} onSave={handleSave} labels={t}>
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							label={t("groups.name")}
							value={name}
							onChange={setName}
							fullWidth
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Checkbox
							label={t("groups.defaultGroup.title")}
							checked={defaultGroup}
							onChange={setDefaultGroup}
						/>
						<FormHelperText>{t("groups.defaultGroup.helperText")}</FormHelperText>
					</Grid>
				</Grid>
			</CardContent>
		</EditForm>
	);
}
