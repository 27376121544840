import {Box, Button, Card, CardContent} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ErrorAlert, TextField} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {useTenantApi} from "../api";
import {useLocalization} from "../i18n";
import {slugify} from "../utils/slugify";
import {View, ViewHeader} from "./View";

export function TenantCreate() {
	const {t} = useLocalization();
	const {createTenant} = useTenantApi();
	const navigate = useNavigate();

	const [name, setName] = useState("");
	const [id, setId] = useState("");
	const [generate, setGenerate] = useState(true);

	const submit = useAsyncCallback(async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		await createTenant({
			id,
			name,
		});
		navigate(`/${id}/settings`);
	});

	useEffect(() => {
		if (generate) {
			setId(slugify(name));
		}
	}, [name, id, generate]);

	function handleChangeId(id: string) {
		setGenerate(!id || id == slugify(name));
		setId(id);
	}

	return (
		<Box py={4}>
			<View maxWidth="md">
				<Breadcrumbs>
					<BreadcrumbLink component={Link} to={".."} relative="path">{t("tenants.title")}</BreadcrumbLink>
					<BreadcrumbItem>{t("tenants.create.title")}</BreadcrumbItem>
				</Breadcrumbs>
				<ViewHeader title={t("tenants.create.title")} />
				<Card>
					<form onSubmit={submit.execute}>
						<CardContent>
							<TextField
								label={t("tenants.name.title")}
								helperText={t("tenants.name.helperText")}
								fullWidth
								value={name}
								onChange={setName}
							/>
						</CardContent>
						<CardContent>
							<TextField
								label={t("tenants.id.title")}
								helperText={t("tenants.id.helperText")}
								fullWidth
								inputProps={{
									pattern: "[a-z0-9-]+",
								}}
								value={id}
								onChange={handleChangeId}
							/>
						</CardContent>
						{submit.error && (
							<CardContent>
								<ErrorAlert error={submit.error} />
							</CardContent>
						)}
						<CardContent>
							<Button variant="outlined" color="primary" type="submit" disabled={submit.loading}>
								{t("tenants.create.title")}
							</Button>
						</CardContent>
					</form>
				</Card>
			</View>
		</Box>
	);
}
