import {LocationAncestry} from "../../api";

export function getLocationPath(location: LocationAncestry | undefined) {
	const path = [];
	while (location) {
		path.push(location);
		location = location.parent;
	}
	return path;
}

export function formatLocationPath(location: LocationAncestry | undefined) {
	return getLocationPath(location).map(location => location.name).join(" | ");
}
