import {ListItem, ListItemText} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@variocube/app-ui";
import React, {Fragment, useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Group, useGroupApi} from "../../api";

export interface GroupSelectProps {
	label: string;
	value: Group | null;
	onChange: (group: Group | null) => void;
	required?: boolean;
}

export function GroupSelect({label, value, onChange, required}: GroupSelectProps) {
	const {queryGroups} = useGroupApi();

	const query = useMemo(() => ({
		size: 1000,
	} as const), []);

	const {loading, error, result} = useAsync(queryGroups, [query]);

	return (
		<Fragment>
			<Autocomplete
				loading={loading}
				options={result?.content ?? []}
				renderInput={(params) => (
					<TextField
						{...params}
						label={label}
						required={required}
					/>
				)}
				getOptionLabel={(group) => group.name}
				renderOption={(props, group, state) => (
					<ListItem {...props} selected={state.selected}>
						<ListItemText primary={group.name} />
					</ListItem>
				)}
				onChange={(_, value) => onChange(value)}
				value={value}
			/>
		</Fragment>
	);
}
