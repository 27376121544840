import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Button, Card, CardContent, CardHeader, FormHelperText, IconButton} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, Checkbox, ErrorAlert, TextField, useFlag} from "@variocube/app-ui";
import React, {FormEvent, useEffect, useState} from "react";
import {useAsyncCallback} from "react-async-hook";
import {Link, useNavigate} from "react-router-dom";
import {useTenantUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {View, ViewHeader} from "../View";

export function UserCreate() {
	const {t} = useLocalization();
	const {createUser} = useTenantUserApi();
	const navigate = useNavigate();

	const [email, setEmail] = useState("");
	const [givenName, setGivenName] = useState("");
	const [familyName, setFamilyName] = useState("");
	const [displayName, setDisplayName] = useState("");
	const [forcePasswordChange, setForcePasswordChange] = useState(true);
	const [password, setPassword] = useState("");

	const [passwordVisible, , , togglePasswordVisible] = useFlag(false);

	const [generate, setGenerate] = useState(true);

	useEffect(() => {
		if (generate) {
			setDisplayName(generateDisplayName(givenName, familyName));
		}
	}, [generate, givenName, familyName]);

	useEffect(() => {
		setGenerate(!displayName || displayName == generateDisplayName(givenName, familyName));
	}, [displayName, givenName, familyName]);

	const submit = useAsyncCallback(async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const tenantUser = await createUser({
			email,
			givenName,
			familyName,
			displayName,
			password,
			forcePasswordChange,
		});
		navigate(`../${tenantUser.user.username}`);
	});

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to="..">{t("users.title")}</BreadcrumbLink>
				<BreadcrumbItem>{t("users.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("users.create.title")} />
			<Card>
				<form onSubmit={submit.execute}>
					<CardHeader title={t("users.name")} />
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									label={t("users.givenName")}
									value={givenName}
									onChange={setGivenName}
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<TextField
									label={t("users.familyName")}
									value={familyName}
									onChange={setFamilyName}
									fullWidth
									required
								/>
							</Grid>
							<Grid item xs={12} md={8}>
								<TextField
									label={t("users.displayName")}
									value={displayName}
									onChange={setDisplayName}
									fullWidth
									required
								/>
							</Grid>
						</Grid>
					</CardContent>
					<CardHeader title={t("users.email")} />
					<CardContent>
						<Grid container>
							<Grid item xs={12} md={8}>
								<TextField
									label={t("users.email")}
									value={email}
									onChange={setEmail}
									type="email"
									fullWidth
									required
								/>
							</Grid>
						</Grid>
					</CardContent>
					<CardHeader title={t("users.password.title")} />
					<CardContent>
						<Grid container>
							<Grid item xs={12} md={8}>
								<TextField
									label={t("users.password.title")}
									value={password}
									onChange={setPassword}
									type={passwordVisible ? "text" : "password"}
									InputProps={{
										endAdornment: (
											<IconButton onClick={togglePasswordVisible}>
												{passwordVisible ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										),
									}}
									fullWidth
								/>
								<FormHelperText>{t("users.password.helperText")}</FormHelperText>
							</Grid>
						</Grid>
					</CardContent>
					<CardContent>
						<Checkbox
							label={t("users.forcePasswordChange.title")}
							checked={forcePasswordChange}
							onChange={setForcePasswordChange}
							disabled={!password}
						/>
						<FormHelperText>{t("users.forcePasswordChange.helperText")}</FormHelperText>
					</CardContent>
					{submit.error && <ErrorAlert error={submit.error} />}
					<CardContent>
						<Button type="submit" variant="contained" color="primary" disabled={submit.loading}>
							{t("users.create.title")}
						</Button>
					</CardContent>
				</form>
			</Card>
		</View>
	);
}

function generateDisplayName(givenName: string, familyName: string) {
	return `${givenName} ${familyName}`;
}
