import {Button} from "@mui/material";
import {
	DataTable,
	DataTableCellProps,
	useDataTableStorage,
	useSpringPage,
	useSpringPageable,
	useStorage,
} from "@variocube/app-ui";
import React, {useMemo} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {Group, useGroupApi} from "../../api";
import {TFunc, useLocalization} from "../../i18n";
import {useTenantPermission} from "../../tenant";
import {View, ViewHeader} from "../View";
import {GroupLinkChip} from "./GroupChip";
import {GroupFilter} from "./GroupFilter";

export function GroupList() {
	const {t} = useLocalization();
	const {queryGroups} = useGroupApi();
	const columns = useMemo(() => createColumns(t), [t]);
	const {onPageChange, onSort, ...storage} = useDataTableStorage("GroupList.paging");
	const pageable = useSpringPageable(storage);
	const [filter, setFilter] = useStorage<GroupFilter>("GroupList.filter", {});

	const canCreate = useTenantPermission("GROUP_CREATE");

	const query = useMemo(() => ({
		...filter,
		...pageable,
	}), [filter, pageable]);

	const {loading, error, result} = useAsync(queryGroups, [query]);
	const {rows, page} = useSpringPage(result);

	return (
		<View>
			<ViewHeader
				title={t("groups.title")}
				actions={canCreate && (
					<Button color="primary" variant="contained" component={Link} to="new">
						{t("groups.create.title")}
					</Button>
				)}
			/>
			<GroupFilter value={filter} onChange={setFilter} onSearch={search => setFilter({...filter, search})} />
			<DataTable
				columns={columns}
				rows={rows}
				page={page}
				loading={loading}
				error={error}
				onSort={onSort}
				onPageChange={onPageChange}
				{...storage}
			/>
		</View>
	);
}

function createColumns(t: TFunc) {
	return [
		{
			label: t("groups.name"),
			field: "name",
			sortable: true,
			default: true,
			component: ({row}: DataTableCellProps<Group>) => <GroupLinkChip group={row} />,
		},
	];
}
