import {Delete} from "@mui/icons-material";
import {Box, Button, CardContent, CardHeader} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {ConfirmIconButton, EditForm, ErrorAlert, RadioGroup} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {
	GroupRole,
	GroupRoleMutation,
	LocationAncestry,
	Permission,
	PERMISSIONS,
	ROLES_TYPES,
	RoleType,
	SYSTEM_ROLES,
	SystemRole,
	useGroupApi,
} from "../../api";
import {useLocalization} from "../../i18n";
import {useNumericParam} from "../../utils/useParam";
import {LocationSelect} from "../location/LocationSelect";

export function GroupRoleList() {
	const {t} = useLocalization();
	const groupId = useNumericParam("groupId");
	const {getGroupRoles, updateGroupRole, createGroupRole, deleteGroupRole} = useGroupApi();

	const {loading, error, result, execute} = useAsync(getGroupRoles, [groupId]);

	const [roles, setRoles] = useState<GroupRole[]>([]);

	useEffect(() => {
		if (result) {
			setRoles(result);
		}
	}, [result]);

	async function handleSave(groupRoleId: number, mutation: GroupRoleMutation) {
		await updateGroupRole(groupId, groupRoleId, mutation);
		await execute(groupId);
	}

	async function handleDelete(groupRoleId: number) {
		await deleteGroupRole(groupId, groupRoleId);
		await execute(groupId);
	}

	const create = useAsyncCallback(async () => {
		await createGroupRole(groupId, {
			permissions: [],
			roleType: "SYSTEM",
			systemRole: "USER",
		});
		await execute(groupId);
	});

	return (
		<Box>
			<Grid container spacing={2} alignContent="stretch">
				{roles.map(role => (
					<Grid key={role.id} item xs={12} md={4}>
						<GroupRoleCard role={role} onSave={handleSave} onDelete={handleDelete} />
					</Grid>
				))}
				<Grid item xs={12} md={4}>
					{create.error && <ErrorAlert error={create.error} />}
					<Button
						color="primary"
						variant="outlined"
						sx={{height: "100%"}}
						fullWidth
						onClick={create.execute}
						disabled={create.loading}
					>
						{t("roles.create.title")}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

interface GroupRoleCardProps {
	role: GroupRole;
	onSave: (id: number, role: GroupRoleMutation) => Promise<any>;
	onDelete: (id: number) => Promise<any>;
}

export function GroupRoleCard({role, onSave, onDelete}: GroupRoleCardProps) {
	const {t} = useLocalization();
	const [location, setLocation] = useState<LocationAncestry | null>(null);
	const [systemRole, setSystemRole] = useState<SystemRole>("USER");
	const [roleType, setRoleType] = useState<RoleType>("SYSTEM");
	const [permissions, setPermissions] = useState<Permission[]>([]);

	useEffect(() => {
		setLocation(role.location ?? null);
		setSystemRole(role.systemRole ?? "USER");
		setRoleType(role.roleType);
		setPermissions(role.permissions ?? []);
	}, [role]);

	async function handleSave() {
		await onSave(role.id, {
			locationId: location?.id,
			systemRole,
			roleType,
			permissions,
		});
	}

	async function handleDelete() {
		await onDelete(role.id);
	}

	return (
		<EditForm loading={false} onSave={handleSave} labels={t}>
			<CardHeader
				title={t("roles.edit.title", role)}
				action={
					<ConfirmIconButton
						icon={<Delete />}
						onConfirm={handleDelete}
						title={t("roles.delete.title")}
						cancel={t("cancel")}
						color="error"
					>
						{t("roles.delete.confirm")}
					</ConfirmIconButton>
				}
			/>
			<CardContent>
				<LocationSelect value={location} onChange={setLocation} fullWidth />
			</CardContent>
			<CardContent sx={{display: "none"}}>
				<RadioGroup
					label={t("roles.roleType.title")}
					value={roleType}
					onChange={setRoleType}
					options={ROLES_TYPES}
				/>
			</CardContent>
			<CardContent>
				<RadioGroup
					label={t("roles.systemRole.title")}
					value={systemRole}
					onChange={setSystemRole}
					options={SYSTEM_ROLES}
				/>
			</CardContent>
		</EditForm>
	);
}

export function hasAllPermissions(permissions: Permission[]) {
	return PERMISSIONS.every(permission => permissions.includes(permission));
}
