import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField, useSpringPageable} from "@variocube/app-ui";
import React from "react";
import {useAsync} from "react-async-hook";
import {TenantUser, useTenantUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {UserIcon} from "../../theme";

export interface UserSelectProps {
	value: TenantUser[];
	onChange: (users: TenantUser[]) => void;
	required?: boolean;
}
export function UserSelect({value, onChange, required}: UserSelectProps) {
	const {t} = useLocalization();
	const {queryUsers} = useTenantUserApi();

	// todo: support for more users
	//  filtering should be server-side
	//  maybe query top users by default
	const pageable = useSpringPageable({pageSize: 1000});

	// todo: display error
	const {loading, error, result} = useAsync(queryUsers, [pageable]);

	// todo: required is currently not validated

	return (
		<Autocomplete
			loading={loading}
			multiple
			options={result?.content ?? []}
			getOptionLabel={user => user.user.displayName}
			renderInput={(params) => (
				<TextField
					{...params}
					label={t("bookings.users.title")}
					InputLabelProps={{
						required,
					}}
				/>
			)}
			renderOption={(props, user, state) => (
				<ListItem {...props} selected={state.selected}>
					<ListItemIcon>
						<UserIcon />
					</ListItemIcon>
					<ListItemText primary={user.user.displayName} secondary={user.user.email} />
				</ListItem>
			)}
			onChange={(_, value) => onChange(value)}
			value={value}
			fullWidth
		/>
	);
}
