import {CardContent, CardHeader, FormHelperText, TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {EditForm} from "@variocube/app-ui";
import React, {ReactNode, useEffect, useState} from "react";
import {CompartmentSettings} from "../../api";
import {useLocalization} from "../../i18n";

interface CompartmentSettingsEditFormProps {
	loading: boolean;
	compartmentSettings?: CompartmentSettings;
	onSave: (compartmentSettings: CompartmentSettings) => Promise<any>;
	title?: ReactNode;
}

export function CompartmentSettingsEditForm(props: CompartmentSettingsEditFormProps) {
	const {t} = useLocalization();
	const {loading, compartmentSettings, onSave, title} = props;

	const [compartmentSizes, setCompartmentSizes] = useState<string[]>([]);

	useEffect(() => {
		if (compartmentSettings) {
			setCompartmentSizes(compartmentSettings.compartmentSizes);
		}
	}, [compartmentSettings]);

	async function handleSave() {
		await onSave({
			compartmentSizes,
		});
	}

	return (
		<EditForm loading={loading} labels={t} onSave={handleSave}>
			{title && <CardHeader title={title} />}
			<CardContent>
				<Autocomplete
					value={compartmentSizes}
					options={compartmentSizes}
					multiple
					freeSolo
					onChange={(_, value) => setCompartmentSizes(value)}
					fullWidth
					renderInput={props => (
						<TextField
							label={t("settings.compartment.compartmentSizes.title")}
							{...props}
						/>
					)}
				/>
				<FormHelperText>{t("settings.compartment.compartmentSizes.helperText")}</FormHelperText>
			</CardContent>
		</EditForm>
	);
}
