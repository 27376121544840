import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs} from "@variocube/app-ui";
import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {GroupMutation, useGroupApi} from "../../api";
import {useLocalization} from "../../i18n";
import {View, ViewHeader} from "../View";
import {GroupEditForm} from "./GroupEditForm";

export function GroupCreate() {
	const {t} = useLocalization();
	const navigate = useNavigate();

	const {createGroup} = useGroupApi();

	async function handleSave(mutation: GroupMutation) {
		const createdGroup = await createGroup(mutation);
		console.log("Created", createdGroup);
		navigate(`../${createdGroup.id}`);
	}

	return (
		<View>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={".."} relative="path">{t("groups.title")}</BreadcrumbLink>
				<BreadcrumbItem>{t("groups.create.title")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("groups.create.title")} />
			<GroupEditForm loading={false} onSave={handleSave} />
		</View>
	);
}
