import React, {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Permission, PERMISSIONS, Tenant, TenantSettings, UserPermissions, useTenantApi} from "./api";
import {LoginState} from "./LoginState";

interface TenantContextContent {
	tenant?: Tenant;
	settings?: TenantSettings;
	permissions: UserPermissions[];
	reload?: () => any;
	setPermissions: (permissions: UserPermissions[]) => void;
}

const TenantContext = createContext<TenantContextContent>({
	permissions: [],
	setPermissions: () => void 0,
});

interface TenantProviderProps {
	tenantId: string;
}

export function TenantProvider({tenantId, children}: PropsWithChildren<TenantProviderProps>) {
	const {getTenant, getTenantSettings, getTenantPermissions} = useTenantApi();

	const [tenant, setTenant] = useState<Tenant>();
	const [settings, setSettings] = useState<TenantSettings>();
	const [permissions, setPermissions] = useState<UserPermissions[]>([]);

	const {error, result, execute} = useAsync(
		(tenantId: string) =>
			Promise.all([
				getTenant(tenantId),
				getTenantSettings(tenantId),
				getTenantPermissions(tenantId),
			]),
		[tenantId],
	);

	useEffect(() => {
		if (result) {
			const [tenant, settings, permissions] = result;
			setTenant(tenant);
			setSettings(settings);
			setPermissions(permissions);
		}
	}, [result]);

	const reload = useCallback(() => execute(tenantId), [execute, tenantId]);

	return (
		<LoginState loading={!tenant} error={error} tenantId={tenantId}>
			<TenantContext.Provider value={{tenant, settings, reload, permissions, setPermissions}}>
				{children}
			</TenantContext.Provider>
		</LoginState>
	);
}

export function useTenantId() {
	const {tenant} = useContext(TenantContext);
	if (!tenant) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return tenant.id;
}

export function useTenantSettings() {
	const {settings} = useContext(TenantContext);
	if (!settings) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return settings;
}

export function useReloadTenant() {
	const {reload} = useContext(TenantContext);
	if (!reload) {
		throw new Error("No tenant found in context. You are probably calling this from outside a TenantProvider.");
	}
	return reload;
}

export function usePermissions() {
	const {permissions} = useContext(TenantContext);
	return permissions;
}

export function useEmulatePermissions() {
	const {permissions, setPermissions} = useContext(TenantContext);
	const setEmulatedPermissions = useCallback((permissions: Permission[]) => {
		setPermissions([{
			location: undefined,
			permissions,
		}]);
	}, [setPermissions]);
	const emulatedPermissions = permissions.find(p => !p.location)?.permissions ?? PERMISSIONS;

	return [emulatedPermissions, setEmulatedPermissions] as const;
}

export function useTenantPermission(permission: Permission) {
	const permissions = usePermissions();
	return Boolean(permissions.find(role => !role.location && role.permissions?.includes(permission)));
}

export function useLocationPermission(permission: Permission, locationId?: number) {
	const permissions = usePermissions();
	return Boolean(
		permissions.find(role =>
			(!role.location || role.location.id == locationId) && role.permissions?.includes(permission)
		),
	);
}

export function useAnyPermission(...requestedPermissions: Permission[]) {
	const permissions = usePermissions();
	return requestedPermissions.some(permission => (
		Boolean(permissions.find(role => role.permissions?.includes(permission)))
	));
}
