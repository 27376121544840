import {Help, Lock, LockOpen} from "@mui/icons-material";
import {Chip, ChipProps} from "@mui/material";
import React from "react";
import {LockStatus} from "../../api";
import {useLocalization} from "../../i18n";

interface LockStatusChipProps extends Pick<ChipProps, "variant"> {
	status: LockStatus;
}

export function LockStatusChip({status, ...chipProps}: LockStatusChipProps) {
	const {t} = useLocalization();

	return (
		<Chip
			icon={getIcon(status)}
			color={getColor(status)}
			variant="filled"
			label={t(`locks.status.${status}`)}
			{...chipProps}
		/>
	);
}

function getColor(status: LockStatus) {
	switch (status) {
		case "OPEN":
			return "success";
		case "CLOSED":
			return "error";
		case "UNKNOWN":
			return "warning";
	}
}

function getIcon(status: LockStatus) {
	switch (status) {
		case "OPEN":
			return <LockOpen />;
		case "CLOSED":
			return <Lock />;
		case "UNKNOWN":
			return <Help />;
	}
}
