import {FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Stack, Typography} from "@mui/material";
import React from "react";
import {LocationType, LocationTypes} from "../../api";
import {useLocalization} from "../../i18n";
import {LocationIcon} from "./LocationIcon";

interface LocationTypeSelect {
	value: LocationType | undefined;
	onChange: (type: LocationType) => any;
}

export function LocationTypeSelect({value, onChange}: LocationTypeSelect) {
	const {t} = useLocalization();
	return (
		<FormControl fullWidth>
			<InputLabel>{t("locations.type")}</InputLabel>
			<Select
				label={t("locations.type")}
				value={value}
				onChange={(event) => {
					onChange(event.target.value as LocationType);
				}}
				fullWidth
				MenuProps={{
					disablePortal: true,
				}}
				renderValue={(type: LocationType) => (
					<Stack direction="row" spacing={1}>
						<LocationIcon type={type} />
						<Typography>{t(`locations.types.${type}`)}</Typography>
					</Stack>
				)}
				variant="outlined"
			>
				{LocationTypes.map(type => (
					<MenuItem key={type} selected={type === value} value={type}>
						<ListItemIcon>
							<LocationIcon type={type} />
						</ListItemIcon>
						<ListItemText primary={t(`locations.types.${type}`)} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
