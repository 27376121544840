import {CardContent, CardHeader, Chip} from "@mui/material";
import Grid from "@mui/material/Grid/Grid";
import {
	Filter,
	PlainDateTime,
	PlainDateTimePicker,
	SpringPageable,
	tryParseInstant,
	useTemporalFormat,
} from "@variocube/app-ui";
import React from "react";
import {AuditLogQuery} from "../../api";
import {useLocalization} from "../../i18n";
import {UserTimeZoneId} from "../booking/BookingFilter";

export type AuditLogFilter = Omit<AuditLogQuery, keyof SpringPageable>;

interface AuditLogFilterProps {
	value: AuditLogFilter;
	onChange: (value: AuditLogFilter) => any;
}

export function AuditLogFilter({value, onChange}: AuditLogFilterProps) {
	const {t, s} = useLocalization();

	const from = tryParseInstant(value.from)?.toZonedDateTimeISO(UserTimeZoneId).toPlainDateTime();
	const until = tryParseInstant(value.until)?.toZonedDateTimeISO(UserTimeZoneId).toPlainDateTime();

	const fromFormatted = useTemporalFormat({value: from});
	const untilFormatted = useTemporalFormat({value: until});

	function handleFromChange(from: PlainDateTime | null) {
		onChange({...value, from: from?.toZonedDateTime(UserTimeZoneId).toInstant().toString()});
	}

	function handleUntilChange(from: PlainDateTime | null) {
		onChange({...value, until: from?.toZonedDateTime(UserTimeZoneId).toInstant().toString()});
	}

	return (
		<Filter
			label={t("filter.title")}
			active={[
				from && (
					<Chip
						label={`${t("audit.from")}: ${fromFormatted}`}
						onDelete={() => onChange({...value, from: undefined})}
					/>
				),
				until && (
					<Chip
						label={`${t("audit.until")}: ${untilFormatted}`}
						onDelete={() => onChange({...value, until: undefined})}
					/>
				),
			]}
			labels={s("filter")}
		>
			<CardHeader title={t("audit.timeframe")} />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item>
						<PlainDateTimePicker
							label={t("audit.from")}
							value={from ?? null}
							onChange={handleFromChange}
						/>
					</Grid>
					<Grid item>
						<PlainDateTimePicker
							label={t("audit.until")}
							value={until ?? null}
							onChange={handleUntilChange}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Filter>
	);
}
