import {Alert, AlertTitle} from "@mui/material";
import {BreadcrumbItem, BreadcrumbLink, Breadcrumbs, ConfirmButton} from "@variocube/app-ui";
import React, {useEffect, useState} from "react";
import {useAsync} from "react-async-hook";
import {Link} from "react-router-dom";
import {Group, GroupMutation, useGroupApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantPermission} from "../../tenant";
import {useNumericParam} from "../../utils/useParam";
import {View, ViewHeader} from "../View";
import {GroupEditForm} from "./GroupEditForm";

export function GroupEdit() {
	const {t} = useLocalization();
	const groupId = useNumericParam("groupId");

	const [group, setGroup] = useState<Group>();

	const {getGroup, updateGroup, deleteGroup} = useGroupApi();
	const {loading, error, result} = useAsync(getGroup, [groupId]);

	const canDelete = useTenantPermission("GROUP_DELETE");

	useEffect(() => {
		setGroup(result);
	}, [result]);

	async function handleSave(mutation: GroupMutation) {
		setGroup(await updateGroup(groupId, mutation));
	}

	async function handleDelete() {
		await deleteGroup(groupId);
	}

	return (
		<View error={error}>
			<Breadcrumbs>
				<BreadcrumbLink component={Link} to={"../.."} relative="path">{t("groups.title")}</BreadcrumbLink>
				<BreadcrumbLink component={Link} to={".."} relative="path">{group?.name}</BreadcrumbLink>
				<BreadcrumbItem>{t("edit")}</BreadcrumbItem>
			</Breadcrumbs>
			<ViewHeader title={t("groups.edit.title", group)} />
			<GroupEditForm loading={loading} group={group} onSave={handleSave} />
			{canDelete && (
				<Alert
					severity="error"
					action={
						<ConfirmButton
							cancel={t("cancel")}
							title={t("groups.delete.title")}
							onConfirm={handleDelete}
							color="error"
						>
							{t("groups.delete.confirm")}
						</ConfirmButton>
					}
				>
					<AlertTitle>
						{t("groups.delete.title")}
					</AlertTitle>
					{t("groups.delete.message")}
				</Alert>
			)}
		</View>
	);
}
