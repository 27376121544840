import {useMemo} from "react";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {createApiClient} from "./client";

export function useKpiApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createKpiApi(tenantId, accessToken), [tenantId, accessToken]);
}

function createKpiApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getTotalBookings: () =>
			api.get("/api/tenants/{tenantId}/kpis/total-bookings", {
				path: {tenantId},
			}),
		getTotalCompartments: () =>
			api.get("/api/tenants/{tenantId}/kpis/total-compartments", {
				path: {tenantId},
			}),
		getTotalUsers: () =>
			api.get("/api/tenants/{tenantId}/kpis/total-users", {
				path: {tenantId},
			}),
		getAverageBookingDuration: () =>
			api.get("/api/tenants/{tenantId}/kpis/average-booking-duration", {
				path: {tenantId},
			}),
		getUtilization: () =>
			api.get("/api/tenants/{tenantId}/kpis/utilization", {
				path: {tenantId},
			}),
	};
}
