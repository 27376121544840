import {Chip, ChipProps} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {User, UserRef} from "../../api";
import {useTenantId} from "../../tenant";
import {UserIcon} from "../../theme";

export interface UserChipProps extends Omit<ChipProps, "label" | "icon"> {
	user: User | UserRef;
}

export function UserChip({user, ...chipProps}: UserChipProps) {
	return (
		<Chip
			icon={<UserIcon />}
			label={user.displayName}
			{...chipProps}
		/>
	);
}

export interface UserLinkChipProps {
	user: User | UserRef;
}

export function UserLinkChip({user}: UserLinkChipProps) {
	const tenantId = useTenantId();
	return (
		<Chip
			icon={<UserIcon />}
			label={user.displayName || user.username}
			component={Link}
			to={`/${tenantId}/users/${user.username}`}
			clickable
			variant="outlined"
			color="secondary"
		/>
	);
}
