import {Delete} from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
} from "@mui/material";
import {ConfirmIconButton, ErrorAlert} from "@variocube/app-ui";
import React, {useState} from "react";
import {useAsync, useAsyncCallback} from "react-async-hook";
import {Group, useTenantUserApi} from "../../api";
import {useLocalization} from "../../i18n";
import {useTenantPermission} from "../../tenant";
import {GroupIcon} from "../../theme";
import {useParam} from "../../utils/useParam";
import {GroupSelect} from "../group/GroupSelect";

export function UserGroupList() {
	const {t} = useLocalization();
	const username = useParam("username");

	const {getUserGroups, addUserToGroup, removeUserFromGroup} = useTenantUserApi();

	const [group, setGroup] = useState<Group | null>(null);

	const {loading, error, result, execute} = useAsync(getUserGroups, [username]);

	const canEdit = useTenantPermission("TENANT_USER_EDIT");

	const add = useAsyncCallback(async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (group) {
			await addUserToGroup(username, group.id);
			await execute(username);
		}
	});

	const remove = useAsyncCallback(async (group: Group) => {
		if (group) {
			await removeUserFromGroup(username, group.id);
			await execute(username);
		}
	});

	return (
		<Box>
			{error && <ErrorAlert error={error} />}
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Card>
						<CardHeader title={t("groups.title")} />
						<List>
							{result?.map(group => (
								<ListItem key={group.id}>
									<ListItemIcon>
										<GroupIcon />
									</ListItemIcon>
									<ListItemText>{group.name}</ListItemText>
									{canEdit && (
										<ListItemSecondaryAction>
											<ConfirmIconButton
												title={t("users.groups.remove.title")}
												cancel={t("cancel")}
												onConfirm={() => remove.execute(group)}
												disabled={remove.loading}
												icon={<Delete />}
												color="error"
											>
												{t("users.groups.remove.confirm", {name: group.name})}
											</ConfirmIconButton>
										</ListItemSecondaryAction>
									)}
								</ListItem>
							))}
							{loading && <Skeleton />}
						</List>
					</Card>
				</Grid>
				{canEdit && (
					<Grid item xs={12} lg={6}>
						<Card>
							<form onSubmit={add.execute}>
								<CardHeader title={t("users.groups.add")} />
								<CardContent>
									<GroupSelect label={t("groups.single")} value={group} onChange={setGroup} />
								</CardContent>
								{add.error && (
									<CardContent>
										<ErrorAlert error={add.error} />
									</CardContent>
								)}
								<CardContent>
									<Button color="primary" type="submit" variant="outlined" disabled={add.loading}>
										{t("users.groups.add")}
									</Button>
								</CardContent>
							</form>
						</Card>
					</Grid>
				)}
			</Grid>
		</Box>
	);
}
