import * as React from "react";
import {createContext, PropsWithChildren, useContext} from "react";
import {useAsync} from "react-async-hook";
import {User, useUserApi} from "./api";
import {LoginState} from "./LoginState";

interface UserContextContent {
	user?: User;
}

const UserContext = createContext<UserContextContent>({});

interface UserProviderProps {
	tenantId?: string;
}

export function UserProvider({tenantId, children}: PropsWithChildren<UserProviderProps>) {
	const {getCurrentUser} = useUserApi();

	const {loading, error, result} = useAsync(getCurrentUser, []);

	return (
		<LoginState loading={loading} error={error} tenantId={tenantId}>
			<UserContext.Provider value={{user: result}}>
				{children}
			</UserContext.Provider>
		</LoginState>
	);
}

export function useUser() {
	const {user} = useContext(UserContext);
	if (!user) {
		throw new Error("No user found in context. Are you missing a UserProvider in your component tree?");
	}
	return user;
}
