import {Typography} from "@mui/material";
import React, {PropsWithChildren} from "react";

interface DisplayFieldProps {
	label: React.ReactNode;
	whiteSpace?: "pre" | "pre-wrap";
}

export function DisplayField({label, children, whiteSpace}: PropsWithChildren<DisplayFieldProps>) {
	return (
		<div>
			<Typography variant="caption" color="textSecondary">
				{label}
			</Typography>
			<Typography variant="body1" component="div" whiteSpace={whiteSpace}>
				{children}
			</Typography>
		</div>
	);
}
