import React from "react";
import {Route, Routes} from "react-router-dom";
import {UserProvider} from "../user";
import {TenantCreate} from "./TenantCreate";
import {TenantSelection} from "./TenantSelection";

export function Tenants() {
	return (
		<UserProvider>
			<Routes>
				<Route path="new" element={<TenantCreate />} />
				<Route index element={<TenantSelection />} />
			</Routes>
		</UserProvider>
	);
}
