import {Avatar, IconButton, useTheme} from "@mui/material";
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Compartment, CompartmentRef} from "../../api";
import {useTenantId} from "../../tenant";

interface CompartmentLinkIconButtonProps {
	compartment: Compartment | CompartmentRef;
}

export function CompartmentLinkIconButton({compartment}: CompartmentLinkIconButtonProps) {
	const tenantId = useTenantId();
	const theme = useTheme();
	return (
		<IconButton component={RouterLink} to={`/${tenantId}/compartments/${compartment.id}`} color="secondary">
			<Avatar variant="rounded" sx={{bgcolor: theme.palette.secondary.main}}>
				{compartment.number}
			</Avatar>
		</IconButton>
	);
}
