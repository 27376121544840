import {Chip, ChipProps} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import {Group, GroupRef} from "../../api";
import {useTenantId} from "../../tenant";
import {GroupIcon} from "../../theme";

export interface GroupChipProps extends Omit<ChipProps, "label" | "icon"> {
	group: Group | GroupRef;
}

export function GroupChip({group, ...chipProps}: GroupChipProps) {
	return (
		<Chip
			icon={<GroupIcon />}
			label={group.name}
			{...chipProps}
		/>
	);
}

export interface GroupLinkChipProps {
	group: Group | GroupRef;
}

export function GroupLinkChip({group}: GroupLinkChipProps) {
	const tenantId = useTenantId();
	return (
		<Chip
			icon={<GroupIcon />}
			label={group.name}
			component={Link}
			to={`/${tenantId}/groups/${group.id}`}
			clickable
			variant="outlined"
			color="secondary"
		/>
	);
}
