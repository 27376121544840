import {SpringPageable} from "@variocube/app-ui";
import {useMemo} from "react";
import {TypeOf} from "zod";
import {useAccessToken} from "../auth";
import {useTenantId} from "../tenant";
import {createApiClient, GroupMutation, GroupRoleMutation, Permission, RoleType, SystemRole} from "./client";
import {get_QueryGroups} from "./client.gen";

export function useGroupApi() {
	const accessToken = useAccessToken();
	const tenantId = useTenantId();
	return useMemo(() => createTenantGroupApi(tenantId, accessToken), [tenantId, accessToken]);
}

export type GroupQuery = TypeOf<get_QueryGroups["parameters"]>["query"];

export const PERMISSIONS = Permission.options.map(option => option.value);
export const ROLES_TYPES = RoleType.options.map(option => option.value);
export const SYSTEM_ROLES = SystemRole.options.map(option => option.value);

function createTenantGroupApi(tenantId: string, accessToken?: string) {
	const api = createApiClient(accessToken);
	return {
		getGroup: (groupId: number) =>
			api.get("/api/tenants/{tenantId}/groups/{groupId}", {
				path: {tenantId, groupId},
			}),
		createGroup: (mutation: GroupMutation) =>
			api.post("/api/tenants/{tenantId}/groups", {
				path: {tenantId},
				body: mutation,
			}),
		updateGroup: (groupId: number, mutation: GroupMutation) =>
			api.put("/api/tenants/{tenantId}/groups/{groupId}", {
				path: {tenantId, groupId},
				body: mutation,
			}),
		deleteGroup: (groupId: number) =>
			api.delete("/api/tenants/{tenantId}/groups/{groupId}", {
				path: {tenantId, groupId},
			}),
		queryGroups: (query: GroupQuery) =>
			api.get("/api/tenants/{tenantId}/groups", {
				path: {tenantId},
				query,
			}),
		getGroupUsers: (groupId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/groups/{groupId}/users", {
				path: {tenantId, groupId},
				query: pageable,
			}),
		getGroupRoles: (groupId: number) =>
			api.get("/api/tenants/{tenantId}/groups/{groupId}/roles", {
				path: {tenantId, groupId},
			}),
		createGroupRole: (groupId: number, mutation: GroupRoleMutation) =>
			api.post("/api/tenants/{tenantId}/groups/{groupId}/roles", {
				path: {tenantId, groupId},
				body: mutation,
			}),
		updateGroupRole: (groupId: number, groupRoleId: number, mutation: GroupRoleMutation) =>
			api.put("/api/tenants/{tenantId}/groups/{groupId}/roles/{groupRoleId}", {
				path: {tenantId, groupId, groupRoleId},
				body: mutation,
			}),
		deleteGroupRole: (groupId: number, groupRoleId: number) =>
			api.delete("/api/tenants/{tenantId}/groups/{groupId}/roles/{groupRoleId}", {
				path: {tenantId, groupId, groupRoleId},
			}),
		getGroupAuditLog: (groupId: number, pageable: SpringPageable) =>
			api.get("/api/tenants/{tenantId}/groups/{groupId}/audit", {
				path: {tenantId, groupId},
				query: pageable,
			}),
	};
}
